import { Container, Row, Col } from 'react-bootstrap'
import logo from '../Images/logo.svg'

function Cover() {
    return (
        <section className="cover">
            <Container>
                <div className="v-mid">
                    <Row className="justify-content-center">
                        <Col>
                            <div className="logo">
                                <img src={logo} alt="www" width="100%" />
                                <div className="logo-text">A decentralised world where realities are augmented by machines that think</div>

                                <div className="start">Scroll to start</div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        </section>
    )
}

export default Cover