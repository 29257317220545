import { Container, Row, Col } from 'react-bootstrap'

function PathThree() {
    return (
        <section>
            <Container>
                <div className="v-mid">
                    <Row className="gx-5">

                        <Col md={9}>
                            <h1>Personal Ownership</h1>
                        </Col>
                        <Col lg={6}>
                            <p>With each evolutionary phase our interactions and activities have become significantly more personal and personalised to us. Smartphones now play such a fundamental role in our day to day lives, in many instances they have become our proof of identity, payment authority, gateway to financial access, and the source and stronghold of our personal health data.</p>
                            <blockquote>
                                Each evolutionary phase in technology has sought to make technology more personal.
                            </blockquote>
                            <p>This trend has placed a significant amount of power and social responsibility on the big tech companies. Over the past years, we’ve seen private companies forced to take positions on politically sensitive issues (vaccinations, elections, etc.). Without debating the merits of the positions, the power to influence society at such scale has typically resided with governments representing it’s citizens. This shift in raises questions around the influence role of big tech companies, and potential gaps in public policy, and the rise of corporatocracy.</p>

                            <p>If we subscribe to this perspective and consider the cascading effects, then the next evolutionary leap is in <strong>“decentralisation”</strong>. A world where people are able to wholly own and control their digital identity, and using that identity access, interact and transact in digital universe with centralised and decentralised organisations.</p>
                        </Col>

                        <Col lg={6}>

                            <Row>
                                <Col lg={6}>
                                    <div className="statbox">
                                        <div className="copy">
                                            Encryption
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div className="statbox">
                                        <div className="copy">
                                            Decentralisation
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div className="statbox">
                                        <div className="copy">
                                            Tokenisation
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div className="statbox">
                                        <div className="copy">
                                            Immutability
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                            <p className="small">The future here is dependant primarily on blockchain technologies to drive an enable and drive the adoption of decentralised identity, currency, finance and services across existing industries.</p>
                        </Col>
                    </Row>

                    <div className="callout">
                        <Row className="gx-5">
                            {/* <Col ><h4>Industry example</h4></Col> */}
                            <Col lg={6}>
                                <div className="callout-pic">
                                    <img src="https://cdn.vox-cdn.com/thumbor/bCGtHTEzUMooihoLdHxL-YdDxL0=/0x0:1920x1080/1200x800/filters:focal(807x387:1113x693)/cdn.vox-cdn.com/uploads/chorus_image/image/70493787/chunky_dunky_vault_nft.0.jpg" alt="pic" width="100%" />
                                </div>
                            </Col>
                            <Col>
                                <h4>Industry example</h4>
                                <p className="small-dark">Most, if not all industries, have explored or ongoing ventures in the blockchain domain. From stores of value, transactions and the broader financial ecosystem, to specific use cases like supply chain/logistics management and crowdsourced cloud computing, the blockchain community are working to understand how various products, services or entire industries can be reimagined in a decentralised ecosystem.</p>
                            </Col>
                        </Row>

                    </div>
                </div>
            </Container>
        </section>
    )
}

export default PathThree