import { Container, Row, Col } from 'react-bootstrap'
import pharmaUI from '../Images/pharmaUI.png'

function PharmaOne() {
    return (
        <section>
            <Container>
                <div className="v-mid">
                    <Row className="gx-5">
                        <Col md={9}>
                            <h1>The web3 experience of buying prescription drugs online.</h1>
                        </Col>

                        <Col md={6}>
                            <p>A patient would be able to use an NFT prescription from the GP issued and transferred to patient during a consultation, to then connect to the pharmaceutical website. The patient would be then presented with information about the drug, administration and price options. </p>

                            <p>In addition, the pharmaceutical website would be able to view other prescription NFTs in the patient wallet and surface any conflicts with existing medication.</p>
                        </Col>

                        <Col md={6}>
                            <p>While there are points to be addressed about managing access to patient information, part of the web3 ethos is security and utility through transparency. Mechanisms could be put in place that allow patients to select which NFTs or token can be accessed by third parties.</p>

                            <p>There are prerequisite for this system to be successful. All members of the eco system would need to reach a higher level of maturity in how web3 technologies are utilised.</p>
                        </Col>

                        <Col md={12}>
                            <div className="herobox">
                                <img src={pharmaUI} alt="l" width={"100%"} />
                            </div>
                        </Col>

                        <Col lg={12}>
                            <Row className="gx-5">
                                <Col lg={12}>
                                    <div className="benebox">
                                        <div className="copy">
                                            Regulators and manufacturers would need to create a standard for smart contracts that issue the prescriptions.
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={12}>
                                    <div className="benebox">
                                        <div className="copy">
                                            An authority to maintain prescription smart contracts and manage access to health professionals
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={12}>
                                    <div className="benebox">
                                        <div className="copy">
                                            Create policies of tokenomics including the issue, use and burn mechanics and access guidelines
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={12}>
                                    <div className="benebox">
                                        <div className="copy">
                                            Support by third party medical software vendors and Pharma to integrate smart contracts onto their platforms
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </Container>
        </section>
    )
}

export default PharmaOne