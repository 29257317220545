import { Container, Row, Col } from 'react-bootstrap'

function PathOne() {
    return (
        <section>
            <Container>
                <div className="v-mid">
                    <Row className="gx-5">

                        <Col md={9}>
                            <h1>Convenience &amp; Availability</h1>
                        </Col>
                        <Col lg={6}>
                            <p>From shared computers at the office and at home, to then personal computers, laptops, mobiles, and finally smart watches and other wearables, each leap has made technology and the web more accessible and available to us. </p>
                            <p>Each phase has sought to make the internet more readily accessible, from remove geographical barriers in the early years, to now reducing the effort and cognitive load required to remain connected - vibrations on your watch rather than taking out your smartphone.</p>
                            <blockquote>
                                Each evolutionary phase in technology has sought to make the internet more convenient and available to us.
                            </blockquote>
                            <p>If we subscribe to this perspective, then the next evolutionary leap could leads us to a world driven by <strong>“ambient computing”</strong>. A world where technology is always available and accessible, aware of context and surrounds, and understanding our intent and needs - our our own personal version of Ironman's JARVIS.</p>
                        </Col>

                        <Col lg={6}>

                            <Row>
                                <Col lg={6}>
                                    <div className="statbox">
                                        <div className="copy">
                                            Natural language process, understanding and generation (NLP, NLU, NLG)
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div className="statbox">
                                        <div className="copy">
                                            Emotion or Sentiment Analysis (EA/SA)
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div className="statbox">
                                        <div className="copy">
                                            Artificial Intelligence (AI) and more broadly Machine Learning (ML)
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div className="statbox">
                                        <div className="copy">
                                            Internet of Things (IoT)
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                            <p className="small">The future here is dependent on a range of key technologies to drive intelligence and understand human context, interaction, interpretation and intelligence. </p>
                        </Col>
                    </Row>

                    <div className="callout">
                        <Row className="gx-5">
                            {/* <Col ><h4>Industry example</h4></Col> */}
                            <Col lg={6}>
                                <div className="callout-pic">
                                    <img src="https://cdn.mobilesyrup.com/wp-content/uploads/2021/08/google-smarthome-product-leaks.jpg" alt="pic" width="100%" />
                                </div>
                            </Col>
                            <Col>
                                <h4>Industry example</h4>
                                <p className="small-dark">Google, well known for their wealth of data and capabilities in machine learning, are well positioned to capitalise and have already heavily invested in this possible future. </p>
                                <p className="small-dark">This is evident in their strategy and commitment ensure their assistant is as available to us as possible through smart home devices (i.e. Google Nest, Home, Audio, Sensors, etc) and their personal smart devices (experience and engineering focus on Google Assistant on their smartphones).</p>
                            </Col>
                        </Row>

                    </div>
                </div>
            </Container>
        </section>
    )
}

export default PathOne