import { Container, Row, Col } from 'react-bootstrap'
import signon from '../Images/signon.png'
import ben3 from '../Images/icons/play.svg'
import ben2 from '../Images/icons/play.svg'
import ben4 from '../Images/icons/play.svg'
import ben1 from '../Images/icons/play.svg'

function ECommOne() {
    return (
        <section>
            {/* <Container fluid>
                <Row>
                </Row>
            </Container> */}
            <Container>
                <div className="v-mid">
                    <Row className="gx-5">
                        <Col md={9}>
                            <h1>A simplified Sign-on experience</h1>
                        </Col>

                        <Col md={6}>
                            <p>From a convince point of view, the example below removes complexity for both customers and the retailers. Customers are no longer required to create an account with each and every single entity they choose to engage with. </p>
                        </Col>

                        <Col md={6}>
                            <p>The need to interact with a 3rd party such as credit cards, Apple Pay or Google Pay to transact and manage payments is also removed from both parties.This enables businesses to focus on what’s important - their core business - rather than managing relationships and integrations in a complex systems. </p>
                        </Col>

                        <Col md={12}>
                            <div className="herobox">
                                <img src={signon} alt="l" width={"100%"} />
                            </div>
                        </Col>

                        <Col lg={12}>
                            <Row className="gx-5">
                                <Col lg={12}>
                                    <div className="benebox">
                                        <div className="copy">
                                            <div>
                                                <img src={ben1} alt="benefit" />
                                            </div>
                                            A simple onboarding on experience
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={12}>
                                    <div className="benebox">
                                        <div className="copy">
                                            <div>
                                                <img src={ben2} alt="benefit" />
                                            </div>
                                            Use wallet for transactions and personalisation
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={12}>
                                    <div className="benebox">
                                        <div className="copy">
                                            <div>
                                                <img src={ben3} alt="benefit" />
                                            </div>
                                            Users hold their own data, provide access as needed
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={12}>
                                    <div className="benebox">
                                        <div className="copy">
                                            <div>
                                                <img src={ben4} alt="benefit" />
                                            </div>
                                            Removes the need for businesses to hold and safeguard customer data
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </Container>
        </section>
    )
}

export default ECommOne