import { Container, Row, Col } from 'react-bootstrap'
import timeline from '../Images/timeline.svg'

function Intro() {
    return (
        <section>
            <Container>
                <div className="v-mid">
                    <Row className="gx-5">
                        <Col md={9}>
                            <h1>Introduction</h1></Col>
                        <Col md={6}>

                            <p>Casting our eyes retrospectively over the progression of internet technology, it's often difficult to identify a single point of origin responsible for the evolutionary path we've seen. If we break down our consumption of the internet into of two domains - what we do (products/services), and how we do it (interfacing devices & infrastructure) - the effects of each upon another are so tightly coupled it's impossible identify distinct causation drivers between both.</p>

                            <blockquote>
                                For example; was it innovations in mobile technology that drove social media adoption? Or was it social media that furthered adoption of mobile devices?
                            </blockquote>

                            <p>While it’s challenging to completely unravel the journey past, we are able to look retrospectively  at a macro level to and identify common patterns or drivers that help us explain the evolutionary pathway. In doing so and provide us some insight into what the future of Web 3.0 may entail.</p>
                        </Col>

                        <Col md={6}>
                            <img src={timeline} width="100%" alt="img" />
                        </Col>
                    </Row>
                </div>
            </Container>
        </section>
    )
}

export default Intro