import { Container, Row, Col } from 'react-bootstrap'
import ecomm from '../Images/ecomm.png'

function EComm() {
    return (
        <section>
            <Container>
                <div className="v-mid">
                    <Row className="gx-5">
                        <Col md={9}>
                            <h1>Web3 eCommerce</h1>
                        </Col>

                        <Col md={6}>
                            <p>Innovations of Web 2.0 has helped business and consumers communicate and engage effectively. Multiple channels allowing customers to switch seamlessly and continue their interactions with businesses</p>

                            <p>While relying on multiple entities has drawbacks it brings with it an important advantage, choice. As customers we have the power to choose who we engage at every level. Google or Apple for identity, Amazon or eBay for order fulfilment, PayPal or Crypto.com for transactions. The choice is endless.</p>

                            <blockquote>
                                Even with the advances in technology, we rely on a daisy chained service layer provided by multiple entities to for the necessary security and utility to transact online.
                            </blockquote>

                            <p>However this choice comes at a price, “ownership of data” and the “right of use”, causing a rift between business and customers. Attempts to solve this issue such as the GDRP have not been proven to scales at a global level.</p>
                            <p>What’s exciting about web 3, apart form the technologies themselves, is the potential it has to change existing power dynamics. The potential to give users back the power over the choice in who they exchange data and eliminate the need for businesses to store and protect copious amounts of personal information. </p>
                        </Col>

                        <Col md={6}>
                            <img src={ecomm} width="100%" alt="img" />
                        </Col>
                    </Row>
                </div>
            </Container>
        </section>
    )
}

export default EComm