import Scrollspy from 'react-scrollspy'
import { Container, Row, Col } from 'react-bootstrap'

function Nav() {

    const ids = [
        "1", "2", "3", "4", "5", "6"
    ]

    const links = [
        "Introduction",
        "Evolutionary Pathways",
        "Shopping online in the age of Web 3.0",
        "NFT Prescriptions",
        // "Motor Insurance DAO",
        "The Futre of Web 3.0"
    ]

    return (
        <Container>
            <Row>
                <Col className="d-none d-lg-block">
                    <nav>
                        <Scrollspy
                            className="links"
                            items={ids}
                            currentClassName="active" >
                            {
                                links.map((e, i) => {
                                    return (
                                        <li key={i}>
                                            <a href={"#" + (i + 1)}>
                                                <var></var>
                                                <span>{e}</span>
                                            </a>
                                        </li>
                                    )
                                })
                            }
                            <li>
                                <a href="https://discord.gg/Qv9qHuVWR3" target="new">
                                    <div className="discord-icon">
                                        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M19.952 5.67206C18.048 4.14106 15.036 3.88206 14.908 3.87106C14.707 3.85406 14.516 3.96806 14.434 4.15206C14.428 4.16406 14.362 4.31506 14.289 4.55006C15.548 4.76206 17.095 5.19006 18.495 6.05906C18.719 6.19806 18.788 6.49306 18.649 6.71806C18.559 6.86406 18.402 6.94406 18.242 6.94406C18.156 6.94406 18.069 6.92106 17.99 6.87206C15.584 5.38006 12.578 5.30506 12 5.30506C11.422 5.30506 8.415 5.38006 6.011 6.87206C5.786 7.01206 5.492 6.94206 5.352 6.71806C5.212 6.49306 5.282 6.19906 5.506 6.05906C6.906 5.19106 8.452 4.76206 9.712 4.55006C9.638 4.31406 9.572 4.16406 9.567 4.15206C9.484 3.96806 9.294 3.85206 9.092 3.87206C8.965 3.88206 5.953 4.14106 4.023 5.69406C3.015 6.62506 1 12.0731 1 16.7831C1 16.8661 1.022 16.9481 1.063 17.0201C2.454 19.4631 6.248 20.1031 7.113 20.1311C7.118 20.1311 7.123 20.1311 7.128 20.1311C7.281 20.1311 7.425 20.0581 7.515 19.9341L8.39 18.7321C6.031 18.1221 4.826 17.0871 4.756 17.0261C4.558 16.8511 4.539 16.5491 4.714 16.3511C4.889 16.1531 5.19 16.1341 5.388 16.3081C5.417 16.3341 7.636 18.2171 12 18.2171C16.372 18.2171 18.591 16.3261 18.613 16.3071C18.811 16.1351 19.113 16.1531 19.287 16.3521C19.461 16.5501 19.442 16.8511 19.245 17.0251C19.175 17.0871 17.97 18.1211 15.611 18.7311L16.486 19.9331C16.576 20.0571 16.72 20.1301 16.873 20.1301C16.878 20.1301 16.883 20.1301 16.888 20.1301C17.753 20.1031 21.547 19.4631 22.938 17.0191C22.978 16.9471 23 16.8661 23 16.7831C23 12.0731 20.985 6.62506 19.952 5.67206ZM8.891 14.8701C7.967 14.8701 7.217 14.0131 7.217 12.9571C7.217 11.9011 7.966 11.0441 8.891 11.0441C9.816 11.0441 10.565 11.9011 10.565 12.9571C10.565 14.0131 9.816 14.8701 8.891 14.8701ZM15.109 14.8701C14.185 14.8701 13.435 14.0131 13.435 12.9571C13.435 11.9011 14.184 11.0441 15.109 11.0441C16.033 11.0441 16.783 11.9011 16.783 12.9571C16.783 14.0131 16.033 14.8701 15.109 14.8701Z" />
                                        </svg>
                                    </div>
                                </a>
                            </li>
                        </Scrollspy>
                    </nav>

                </Col>
            </Row>
        </Container>
    )
}

export default Nav