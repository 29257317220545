import { Container, Row, Col } from 'react-bootstrap'
import cloths from '../Images/cloths.png'
import skin from '../Images/skin.png'

function ECommTwo() {
    return (
        <section id="things">
            {/* <Container fluid>
                <Row>
                </Row>
            </Container> */}
            <Container>
                <div className="v-mid">
                    <Row className="gx-5">
                        <Col md={9}>
                            <h1>A truly personalised one-to-one customer experience with Tokens</h1>
                        </Col>

                        <Col md={6}>
                            <p>Web3 eCommerce platforms will have the opportunity to provide an ‘on-demand’ shopping experience. The ability draw from and analyse historical wallet transaction data removes the risk associated with the burden in holding and safeguarding sensitive customer data.  </p>
                            <blockquote className="nomargin">
                                Tokenomics could create a whole new way of on-demand information sharing to fulfil customer needs
                            </blockquote>
                            <p>From a digital analytics perspective, it also removes the need to rely on anonymised demographic data to 'estimate' customer needs. Web 3.0 provides an opportunity to engage “one-to-one” resulting in a much richer customer experience.</p>
                        </Col>

                        <Col md={6}>

                            <p>In the below example, customers who ‘connect’ their wallet would be able to purchase various products using crypto currency. Potentially remving geographic barriers to commodity price integration. </p>
                            <p>Users could use a recognised thirdparty contracts to create tokens that hold certain information such as body measurements and hold it in the wallet connecting to the eCommerce website.</p>
                            <p>This would mean that the eComm platform could provide a targetted experience, triangulated using the biometric tokens and purchase history of the wallet using the wallet address.</p>
                        </Col>

                        <Col md={12}>
                            <div className="herobox">
                                <img src={cloths} alt="l" width={"100%"} />
                            </div>
                        </Col>

                        <Col md={6}>
                            <p>In the following example the same biometric token could be created that holds additional data about skin type, preferred ingredients, allergies and preferred sourcing and manufacturing methods. Access to this on-demand information by a smart eCommerce platform has the potential to present an experience that is nuanced and tailored.</p>
                        </Col>

                        <Col md={12}>
                            <div className="herobox">
                                <img src={skin} alt="l" width={"100%"} />
                            </div>
                        </Col>

                        <Col lg={12}>
                            <Row className="gx-5">
                                <Col md={12}>
                                    <div className="benebox">
                                        <div className="copy">
                                            A richer one-to-one shopping experience
                                        </div>
                                    </div>
                                </Col>
                                <Col md={12}>
                                    <div className="benebox">
                                        <div className="copy">
                                            Give back power over use of data back to the user
                                        </div>
                                    </div>
                                </Col>
                                <Col md={12}>
                                    <div className="benebox">
                                        <div className="copy">
                                            Remove burden of storing personal information
                                        </div>
                                    </div>
                                </Col>
                                <Col md={12}>
                                    <div className="benebox">
                                        <div className="copy">
                                            Opportunity for investment into better logistics and sustainable manufacturing processes
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>



                        <Col md={6}>
                        </Col>

                        <Col md={6}>
                        </Col>
                    </Row>
                </div>
            </Container>
        </section>
    )
}

export default ECommTwo