import { Container, Row, Col } from 'react-bootstrap'
import ben1 from '../Images/icons/layout-footer.svg'

function Paths() {
    return (
        <section className="cover">
            <Container>
                <div className="v-mid">
                    <Row className="justify-content-center">

                        <Col md={6} className="t-center">
                            <h1>Evolutionary Pathways</h1>
                            <p>If we retrospectively look at the evolution of technology from different perspectives, we’re able to identify 3 common threads that might help us understand the different driving forces of web.</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={4}>
                            <div className="pathway path1">
                                <div className="copy">
                                    {/* <img src={ben1} alt="ben" /> */}
                                    <h4>Convenience &amp;<br />Availability</h4>
                                    {/* <p>Each evolutionary phase in technology has sought to make the internet more convenient and available to us.</p> */}
                                </div>
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className="pathway path2">
                                <div className="copy">
                                    {/* <img src={ben1} alt="ben" /> */}
                                    <h4>Abstraction &amp;<br />Immersion</h4>
                                    {/* <p>Each evolutionary phase in technology has sought to make the internet more convenient and available to us.</p> */}
                                </div>
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className="pathway path3">
                                <div className="copy">
                                    {/* <img src={ben1} alt="ben" /> */}
                                    <h4>Personal<br />Ownership</h4>
                                    {/* <p>Each evolutionary phase in technology has sought to make the internet more convenient and available to us.</p> */}
                                </div>
                            </div>
                        </Col>

                    </Row>
                </div>
            </Container>
        </section>
    )
}

export default Paths