import { Container, Row, Col } from 'react-bootstrap'
import vr from '../Images/VR.png'
import unreal from '../Images/unreal.png'

function ECommThree() {
    return (
        <section>
            {/* <Container fluid>
                <Row>
                </Row>
            </Container> */}
            <Container>
                <div className="v-mid">
                    <Row className="gx-5">
                        <Col md={9}>
                            <h1>Stepping into the Metaverse</h1>
                        </Col>

                        <Col md={6}>
                            <p>The more advanced stages of the web3 eCommerce platform evolution could see users stepping into a virtual world not unlike the ones portrayed in sci-fi movies such as Ready Player One.</p>
                            <blockquote className="nomargin">
                                It’s not far reaching to suggest that we are on the cusp of true virtual shopping in the metaverse.
                            </blockquote>
                            <p>Pioneers of meta humans such as Samsung NEON and Unreal Engine are making strides in bringing the capability of digital twins to masses. </p>
                        </Col>

                        <Col md={6}>

                            <p>At present, this level of character customisation is restricted to games (i.e. Cyber Punk 2077) and low fidelity online experiences such as Decentraland. It isn’t hard to imagine how this capability will change virtual shopping experiences in the future.</p>
                        </Col>

                        <Col md={12}>
                            <div className="herobox">
                                <img src={vr} alt="l" width={"100%"} />
                                <img src={unreal} alt="l" width={"100%"} />
                            </div>
                        </Col>

                        <Col lg={12}>
                            <Row className="gx-5">
                                <Col md={12}>
                                    <div className="benebox">
                                        <div className="copy">
                                            Richer online experiences blurring the lines between the virtual and physical
                                        </div>
                                    </div>
                                </Col>
                                <Col md={12}>
                                    <div className="benebox">
                                        <div className="copy">
                                            Reduce wastage and over manufacturing
                                        </div>
                                    </div>
                                </Col>
                                <Col md={12}>
                                    <div className="benebox">
                                        <div className="copy">
                                            Transparency across the end-to-end product cycle
                                        </div>
                                    </div>
                                </Col>
                                <Col md={12}>
                                    <div className="benebox">
                                        <div className="copy">
                                            Opportunity for investment into better logistics and sustainable manufacturing processes.
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>



                        <Col md={6}>
                        </Col>

                        <Col md={6}>
                        </Col>
                    </Row>
                </div>
            </Container>
        </section>
    )
}

export default ECommThree