import { Container, Row, Col } from 'react-bootstrap'
import pharma from '../Images/pharma.svg'

function PharmaTwo() {
    return (
        <section>
            <Container>
                <div className="v-mid">
                    <Row className="gx-5">
                        <Col md={9}>
                            <h1>The new eco system with NFTs</h1>
                        </Col>

                        <Col md={6}>
                            <p>Non-fungible tokens (NFTs) provide an interesting alternative to solving some of the inherent  issues, with the added benefit of providing more control and anonymity to the user. </p>

                            <p>The immutability of NFTs make them a great option for holding prescription data while benefitting from the transactional aspects on a blockchain. Users can benefit from controlling the amount of personal information shared while still maintaining ownership of the prescription.</p>

                            <p>This application of NFTs showcases an approach of how governments could leverage blockchain - a technology inherently designed for decentralisation - and meet both the needs of government and community. Striking a balance between regulatory governance and oversight, while providing control and privacy to citizens.</p>
                        </Col>

                        <Col md={6}>
                            <img src={pharma} alt="l" width={"100%"} />
                        </Col>

                    </Row>
                </div>
            </Container>
        </section>
    )
}

export default PharmaTwo