import { Container, Row, Col } from 'react-bootstrap'

function Pharma() {
    return (
        <section>
            <Container>
                <div className="v-mid">
                    <Row className="gx-5">
                        <Col md={9}>
                            <h1>NFT Prescriptions for improved compliance, visibility, and safety.</h1></Col>
                        <Col md={6}>

                            <p>The way prescriptions are issued, fulfilled, and are traced has for the most part remained in the physical realm. The pandemic in the last years acting as a catalyst, has forced this archaic industry segment to go digital with ePrescriptions, benefitting everyone in the eco system. </p>

                            <blockquote>
                                NFTs, once created are immutable. No matter how many transactions NFTs are used in, they inherantly traceable back to the point of origin.
                            </blockquote>

                            <p>The recent rollout of electronic prescriptions, as part of a wider framework to support safety in Australia directly addresses the issues in visibility of prescribing, dispensing and claiming of medicines.</p>
                        </Col>

                        <Col md={6}>
                            <p>Although a step in the right direction, the change has been somewhat lacking by only transferring the physical format to digital.</p>

                            <p>Enter blockchain. There are many ways that blockchain can improve the supply and value chain in the pharmaceutical industry. Among the topmost apparent benefits are provenance, regulation compliance and traceability.</p>
                        </Col>
                    </Row>
                </div>
            </Container>
        </section>
    )
}

export default Pharma