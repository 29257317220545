import { Container, Row, Col } from 'react-bootstrap'

function PathTwo() {
    return (
        <section>
            <Container>
                <div className="v-mid">
                    <Row className="gx-5">

                        <Col md={9}>
                            <h1>Abstraction &amp; Immersion</h1>
                        </Col>
                        <Col lg={6}>
                            <p>From PCs and laptops, to smartphone ecosystems, to then web apps and smart devices, each evolutionary phase has sought abstract itself away from the under underlying technological ecosystem or landscape to a more connected and immersive one.</p>
                            <p>The journey early in early years was simply about providing you the space and privacy to be more immersed in the devices (shift from shared to personal devices), however the progression has now enabled us now to be immersed in a connected reality with health tracking, sensors and smart devices working towards an interconnected and interoperable ecosystem.  </p>
                            <blockquote>
                                Each evolutionary phase in technology has sought to abstracting away from an existing technology ecosystem to a more immersive one.
                            </blockquote>

                            <p>If we subscribe to this perspective, then the next evolutionary leap leads us to a world driven various <strong>“extended realities”</strong> and their ecosystems. A fully digital world, co-existing in conjunction with physical world to various degrees along a gradient of immersion and connection.</p>
                        </Col>

                        <Col lg={6}>

                            <Row>
                                <Col lg={6}>
                                    <div className="statbox">
                                        <div className="copy">
                                            Virtual Reality (VR)
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div className="statbox">
                                        <div className="copy">
                                            Augmented Reality (AR)
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div className="statbox">
                                        <div className="copy">
                                            Mixed Reality (MR)
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div className="statbox">
                                        <div className="copy">
                                            Computer Vision
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                            <p className="small">The future here is dependent on a range of technologies to craft digital worlds (realities, relationships, connections, assets, etc), but also digitisation of the physical world. </p>
                        </Col>
                    </Row>

                    <div className="callout">
                        <Row className="gx-5">
                            {/* <Col ><h4>Industry example</h4></Col> */}
                            <Col lg={6}>
                                <div className="callout-pic">
                                    <img src="https://img.olhardigital.com.br/wp-content/uploads/2021/10/meta.jpeg" alt="pic" width="100%" />
                                </div>
                            </Col>
                            <Col>
                                <h4>Industry example</h4>
                                <p className="small-dark">Meta (previously Facebook), with their wealth of social data and strength of their network utility across their various platforms, are well positioned to capitalise on the digitisation of relationships and have invested heavily in this domain. Their prior acquisition of Virtual Reality company Oculus ($2Bn), recent spree of metaverse acquisitions, and rebranding to Meta demonstrates their commitment to VR and the metaverse more broadly.</p>
                                <p className="small-dark">Another industry example here is Microsoft, who have elected to have ventured down a path Mixed Reality pathway, providing native Mixed Reality support on Windows, and creating the Microsoft HoloLens with commercial applications in mind. Their recent acquisition of Activation Blizzard ($68Bn) strengthens their foothold in the gaming space and further opens up opportunities for them to explore the metaverse and more specifically, the non-fungible tokens (NFTs) market.</p>
                            </Col>
                        </Row>

                    </div>
                </div>
            </Container>
        </section>
    )
}

export default PathTwo