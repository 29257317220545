import { Container, Row, Col } from "react-bootstrap"

function Breaker(props) {
    return (
        <section className="breaker">
            <Container>
                <div className="v-mid">
                    <Row>
                        <Col lg={9}>
                            <div className="breaker">
                                <h1 className="large">{props.title}</h1>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        </section>
    )
}

export default Breaker