import Cover from "./Components/0.cover";
import Intro from "./Components/1.intro";
import Paths from "./Components/2.paths";
import PathOne from "./Components/3.path-1";
import PathTwo from "./Components/4.path-2";
import PartThree from "./Components/5.path-3";
import ProgressBar from "react-scroll-progress-bar";
import EComm from "./Components/7.eComm";
import Breaker from "./Components/breaker";
import IntroEnd from "./Components/6.intro-end";
import ECommOne from "./Components/8.eComm-1";
import ECommTwo from "./Components/9.eComm-2";
import ECommThree from "./Components/10.eComm-3";
import Nav from "./Components/nav";
import Pharma from "./Components/11.Pharma";
import PharmaOne from "./Components/12.Pharm-1";
import PharmaTwo from "./Components/13.Pharm-2";
import Motor from "./Components/14.Motor";
import Footer from "./Components/30.footer";

function App() {

  return (
    <>
      <ProgressBar
        height="6px"
        bgcolor="#EB3352"
        duration="1"
      />
      <Nav />
      <Cover />
      <div id="1" className="page">
        <Intro />
      </div>
      <div id="2" className="page">
        <Paths />
        <PathOne />
        <PathTwo />
        <PartThree />
      </div>
      <div id="3" className="page">
        <Breaker title={"Shopping online in the age of Web 3.0"} />
        <ECommOne />
        <ECommTwo />
        <ECommThree />
        <EComm />
      </div>
      <div id="4" className="page">
        <Breaker title={"NFT Prescriptions"} />
        <Pharma />
        <PharmaOne />
        <PharmaTwo />
      </div>
      <div id="5" className="page">
        <IntroEnd />
      </div>
      <Footer />
    </>
  );
}

export default App;