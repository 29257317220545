import { Container, Row, Col } from 'react-bootstrap'
import timeline from '../Images/timeline.svg'

function Footer() {
    return (
        // <section>
        <Container>
            <div className="v-mid">
                <Row>
                    <Col md={12}>
                        <p className="small">All the information published on this website, or in any article herein is true and accurate to the best of the authors’ knowledge. Information on this site should not be a substitute for legal advice. No liability is assumed by PossibleFutures, or individual NZ LAW member firms for losses suffered by any person or organisation relying directly or indirectly on information published on this site. Views expressed in any article are the views of the authors individually and do not necessarily reflect the view of NZ LAW or their member firms.</p>

                        <p className="small">Information appearing on this site may only be reproduced with prior approval from PossibleFutures Head Office, and credit being given to the source.</p>

                        <p className="small">&copy; PossibleFutures 2022.</p>
                    </Col>
                </Row>
            </div>
        </Container>
        // </section>
    )
}

export default Footer