import { Container, Row, Col } from 'react-bootstrap'
import whatfuture from '../Images/what-future.png'

function IntroEnd() {
    return (
        <section className="introend">
            <Container>
                <div className="v-mid">
                    <Row>

                        <Col md={9}>
                            <h1 className="mid">So, what does the Web 3.0 future look like?</h1></Col>
                        <Col md={6}>

                            <p>Evident in these patterns is that the journey taken to date has certainly not been driven by mutually exclusive trends. There are overlapping themes across the aspirational goals, and the different perspectives reinforce the notion that while there is strong correlation amongst drivers, it’s difficult to distinctly identify causation points amongst them. Tech giants themselves take a hedged positions on what future technology will prove dominant.</p>

                            <blockquote>
                                Will the success and adoption of decentralised services impact the adoption of Meta’s VR metaverse? How will Google adapt it’s Google Assistant in a world of decentralised identities?
                            </blockquote>

                            <p>The reality is that these futures are not mutually exclusive. Each technology's journey will influence and sway the journey of others. As each technology advances on it’s own journey through their respective innovation and maturity lifecycles, each step they take will influence the pathway of others. Because of this, we view the collective set of technologies as Web 3.0.</p>
                        </Col>

                        <Col md={6}>
                            <img src={whatfuture} width="100%" alt="img" />
                        </Col>
                    </Row>
                </div>
            </Container>
        </section>
    )
}

export default IntroEnd